import { Http } from "../utils/http";

export class GameBoxService extends Http {
	constructor() {
		super();
	}

	create(payload) {
		return this.httpClient().post(`gameboxes`, payload);
	}

	get({ search, paginated, skip, limit }) {
		if (!search) search = "";
		return this.httpClient().get(`gameboxes?search=${search}`, {
			paginated,
			skip,
			limit,
		});
	}

	update(payload) {
		return this.httpClient().patch(`gameboxes/${payload._id}`, payload);
	}

	togglePower(serialNumber) {
		return this.httpClient().patch(`integrations/iot/${serialNumber}/power`);
	}

	delete(gameBoxId) {
		return this.httpClient().delete(`gameboxes/${gameBoxId}`);
	}

	exportMachines(payload) {
		return this.httpClient().get("gameboxes/export", { params: payload, responseType: "blob" });
	}
}
