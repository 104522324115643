import { UserService } from "../../services/auth.service";
import storageService from "../../services/storage.service";
import { menuItems } from "@/components/menu";

const service = new UserService();

export const state = {
	currentUser: storageService.getItem("user"),
	menuItems: menuItems,
};

export const mutations = {
	SET_CURRENT_USER(state, user) {
		state.currentUser = user;
	},
};

export const getters = {
	// Whether the user is currently logged in.
	loggedIn(state) {
		return !!state.currentUser;
	},

	user(state) {
		return state.currentUser;
	},

	isAdmin({ currentUser }) {
		return currentUser?.role === "ADMIN";
	},

	menu({ currentUser, menuItems }) {
		if (!currentUser) return [];
		switch (currentUser.role) {
			case "BRANCH-MANAGER":
				return menuItems[0];
			case "EMPLOYEE":
				return [];
			case "ADMIN":
				return menuItems;
			default:
				return [];
		}
	},
};

export const actions = {
	// This is automatically run in `src/state/store.js` when the app
	// starts, along with any other actions named `init` in other modules.
	// eslint-disable-next-line no-unused-vars
	init({ state, dispatch }) {
		dispatch("validate");
	},

	// Logs in the current user.
	async login({ commit }, { email, password } = {}) {
		// if (getters.loggedIn) return dispatch('validate');
		const result = await service.login({ email, password });
		if (result.data) {
			const { user } = result.data;
			const { shouldChangePassword, isActive, ...userData } = user;
			commit("SET_CURRENT_USER", userData);
			saveState("user", userData);
			return { message: "Successfully Logged In", user };
		} else {
			return result;
		}
	},

	// Logs out the current user.
	async logout({ commit, state }) {
		const result = await service.logout({ userId: state.currentUser._id });
		// eslint-disable-next-line no-unused-vars
		commit("SET_CURRENT_USER", null);
		storageService.clear();
		return result;
	},

	async requestPasswordChange({ commit }, { email }) {
		const result = await service.requestPasswordChange(email);
		return result;
	},

	async verifyPasswordChange({ commit }, { token }) {
		const result = await service.verifyPasswordChange(token);
		return result;
	},
};

// ===
// Private helpers
// ===
function saveState(key, state) {
	storageService.setItem(key, JSON.stringify(state));
}
