import { Http } from "../utils/http";

export class UserService extends Http {
  constructor(){
    super();
  }

  requestPasswordChange(email) {
    return this.httpClient().post(`auth/${email}/request-password-change`);
  }

  verifyPasswordChange(token){
    return this.httpClient().patch(`auth/confirm-password-change/${token}`)
  }

  login({ email, password}) {
    return this.httpClient().post('auth/log-in', { email, password});
  }

  logout({ userId }) {
    return this.httpClient().post(`auth/log-out/${userId}`);
  }
}