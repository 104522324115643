import { TransactionService } from "../../services/transactions.service";
var FileSaver = require("file-saver");
const service = new TransactionService();

export const state = {
	transactions: [],
	transactionTotal: 0,
	transactionSummary: [],
	transactionToConfirm: [],
};

export const mutations = {
	SET_TRANSACTIONS(state, transactions) {
		state.transactions = [...transactions];
	},

	SET_TRANSACTION_SUMMARY(state, summary) {
		state.transactionSummary = summary;
	},

	SET_TRANSACTION_TOTAL(state, total) {
		state.transactionTotal = total;
	},

	SET_TRANSATCION_TO_CONFIRM(state, transaction) {
		state.transactionToConfirm = transaction;
	},
};

export const actions = {
	async getSingleTransaction(_, id) {
		return await service.getSingleTransaction(id);
	},

	async markComplete(_, id) {
		return await service.markComplete(id);
	},

	async createTransaction({ commit }, payload) {
		const result = await service.create(payload || {});
		if (result.status == 201) {
			commit("SET_TRANSATCION_TO_CONFIRM", result.data);
			return result;
		} else {
			return result;
		}
	},

	async createReinjection(_, data) {
		const result = await service.createReinjection(data || {});
		return result.data;
	},

	async getTransactions({ commit }, payload) {
		const { status, data, error, message } = await service.getTransactions(payload || {});
		const { results, count } = data;
		if (status == 200) {
			commit("SET_TRANSACTIONS", results);
			commit("SET_TRANSACTION_TOTAL", count);
			return { status, data };
		} else {
			return { status, error, message };
		}
	},

	async getMonthOrWeekTransaction({ commit }, { branchId, period, startDate, endDate }) {
		const result = await service.getMonthOrWeekTransaction(
			period,
			"",
			startDate,
			endDate,
			branchId
		);
		return result;
	},

	// Get transaction details after recording gambox readings for a location
	async getTransactionSummaryDetails(_, payload) {
		const result = await service.getTransactionSummaryDetails(payload || {});
		return result;
	},

	async getTransactionSummary(
		{ commit },
		{ groupBy, branchId, gameId, gameboxId, startDate, endDate }
	) {
		const result = await service.getTransactionSummary(
			groupBy,
			"",
			branchId,
			gameId,
			gameboxId,
			startDate,
			endDate
		);

		if (result.status == 200) {
			commit("SET_TRANSACTION_SUMMARY", result.data[0]);
			return result;
		} else {
			return result;
		}
	},

	async confirmTransaction({ confirm }, { transactionId }) {
		const result = await service.confirm(transactionId);
		return result;
	},

	async uploadTransactionCSV({ commit }, fileUpload) {
		const result = await service.upload(fileUpload);
		return result;
	},
	async deleteTransaction({ commit }, id) {
		const result = await service.delete(id);
		return result;
	},

	async updateTransaction({ commit }, payload) {
		const result = await service.update(payload);
		return result;
	},

	async getLatestIotTransactions(_, id) {
		const result = await service.getLatestIotTransactions(id);
		return result;
	},

	async markIotComplete(_, payload) {
		const result = await service.markIotComplete(payload);
		return result;
	},

	async getIotPowerState(_, id) {
		const result = await service.getIotPowerState(id);
		return result;
	},

	async getPastTransactionDates(_, query) {
		const result = await service.getPastTransactionDates(query);
		return result;
	},

	async getFloatReinjection(_, payload) {
		const result = await service.getFloatReinjection(payload);
		return result.data;
	},

	// Method used to export the transactions based on teh params passed in.
	// Will return a file
	async exportTransactions(_, payload) {
		return service.exportTransactions(payload);
	},

	async exportAdditionalFloat(_, payload) {
		const { data } = await service.exportAdditionalFloat(payload);

		// create a blob
		const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
		FileSaver.saveAs(blob, "additional-float.csv");

		return data;
	},
};

export const getters = {
	getTransactionsByBranch: (state) => (branchId) =>
		state.transactions.filter((transaction) => transaction.branch._id == branchId),
};
