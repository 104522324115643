import { GamesService } from "../../services/games.service";
var FileSaver = require("file-saver");
const service = new GamesService();

export const state = {
	games: [],
	gameSearched: {},
	totalGames: 0,
	paginationConfig: {
		skip: "",
		limit: "",
		search: "",
		paginated: false,
	},
};

export const mutations = {
	SET_GAMES(state, games) {
		state.games = [...games];
	},

	SET_TOTAL_GAMES(state, total) {
		state.totalGames = total;
	},

	ADD_GAME(state, game) {
		state.push(game);
	},

	SET_GAME_SEARCHED(state, game) {
		state.gameSearched = { ...game };
	},
};

export const actions = {
	async createGame({ dispatch }, { name, description, pulseRate, isActive }) {
		const result = await service.create(name, description, pulseRate, isActive);
		if (result.status == 201) {
			dispatch("getGames");
			return result;
		} else {
			return result;
		}
	},

	async updateGame({ dispatch }, { _id, name, description, pulseRate, isActive }) {
		const result = await service.update(_id, name, description, pulseRate, isActive);
		if (result.status == 200) {
			dispatch("getGames");
			return result;
		} else {
			return result;
		}
	},

	async getGames({ commit, state }) {
		const { paginated, skip, limit } = state.paginationConfig;
		const result = await service.get({ paginated, skip, limit });
		if (result.status == 200) {
			const { results, count } = result.data;
			let games = [];
			if (Array.isArray(results)) {
				games = results.filter((game) => !game.hasOwnProperty("isDeleted"));
			} else {
				games = [];
			}
			commit("SET_GAMES", games);
			commit("SET_TOTAL_GAMES", count);
			return;
		} else {
			return result;
		}
	},

	async getGame({ commit }, { search }) {
		const result = await service.get({ search });
		if (result.status == 200) {
			const { results } = result.data;
			commit("SET_GAME_SERACHED", results ? results[0] : {});
			return result;
		} else {
			return result;
		}
	},

	async update({ dispatch }, { gameId, name, description, pulseRate, isActive }) {
		const data = await service.update(gameId, name, description, pulseRate, isActive);
		const { statusCode } = data;

		if (!statusCode) {
			dispatch("getGames");
		} else {
			return data;
		}
	},

	async deleteGame({ dispatch }, { gameId }) {
		const data = await service.delete(gameId);
		if (data.status == 204) {
			dispatch("getGames");
			return data;
		} else {
			return data;
		}
	},

	// Method used to export games
	async exportGames(_, payload) {
		const { data } = await service.exportGames(payload);
		// create a blob
		const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
		FileSaver.saveAs(blob, "games.csv");

		return data;
	},
};

export const getters = {
	getGameById: (state) => (id) => state.games.find((game) => game._id == id),
};
