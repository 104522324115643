import { Http } from "../utils/http";

export class GamesService extends Http {
	constructor() {
		super();
	}

	create(name, description, pulseRate, isActive) {
		return this.httpClient().post("games", { name, description, pulseRate, isActive });
	}

	get({ search, paginated, skip, limit }) {
		if (!search) search = "";
		else search = `?search=${search}`;
		return this.httpClient().get(`games${search}`, { paginated, skip, limit });
	}

	update(gameId, name, description, pulseRate, isActive) {
		return this.httpClient().patch(`games/${gameId}`, { name, description, pulseRate, isActive });
	}

	delete(gameId) {
		return this.httpClient().delete(`games/${gameId}`);
	}

	exportGames(payload) {
		return this.httpClient().get("games/export", { params: payload, responseType: "blob" });
	}
}
