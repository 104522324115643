export default {
  /**
   * Fetch a stored value provided the key from local or session storage based on the environment
   * If cannot find item by key then it returns NULL
   * @param {string} key 
   */
  getItem(key) {
    let value;
    
    if(process.env.VUE_APP_STORAGE == "sessionStorage") {
      value = sessionStorage.getItem(key)
    } else {
      value = localStorage.getItem(key)
    }

    try {
      return JSON.parse(value)
    } catch {
      return value
    }
  },

  /**
   * Stores a key-value pair in local or session storage based on the environment
   * @param {string} key 
   * @param {any} value 
   */
  setItem(key, value) {
    if(process.env.VUE_APP_STORAGE == "sessionStorage") {
      sessionStorage.setItem(key, value)
    } else {
      localStorage.setItem(key, value)
    }
  },

  /**
   * Remove items from session and local storage
   */
  clear() {
    sessionStorage.clear()
    localStorage.clear()
  } 
}