import { UsersServices } from "../../services/users.service";
var FileSaver = require("file-saver");
const service = new UsersServices();

export const state = {
	users: [],
	userSearched: {},
	totalUsers: 0,
	paginationConfig: {
		skip: "",
		limit: "",
		search: "",
		paginated: false,
	},
};

export const mutations = {
	SET_USERS(state, users) {
		state.users = [...users];
	},

	SET_TOTAL_USERS(state, total) {
		state.totalUsers = total;
	},

	SET_USER_SEARCHED(state, user) {
		state.userSearched = { ...user };
	},
};

export const actions = {
	async createUser({ dispatch }, { name, email, role, isActive }) {
		const { status, message, error } = await service.create(name, email, role, isActive);

		if (status == 201) {
			dispatch("getUsers");
			return { status };
		} else {
			return { message, status, error };
		}
	},

	async getUsers({ state, commit }) {
		const { paginated, skip, limit } = state.paginationConfig;
		const result = await service.get({ paginated, skip, limit });
		if (result.status == 200) {
			const { results, count } = result.data;
			let users = [];
			if (Array.isArray(results))
				users = results.filter(
					(user) => user.isDeleted == false || !user.hasOwnProperty("isDeleted")
				);
			else users = [];

			commit("SET_USERS", users);
			commit("SET_TOTAL_USERS", count);
			return result;
		} else {
			return result;
		}
	},

	async getUser({ commit }, { search }) {
		const { data, status, message, error } = await service.get({ search });

		if (status == 200) {
			const { results } = data;
			commit("SET_USER_SEARCHED", results ? results[0] : {});
			return;
		} else {
			return { status, message, error };
		}
	},

	async updateUser({ dispatch }, { _id, name, email, role, isActive }) {
		const result = await service.update(_id, name, email, role, isActive);

		if (result.status == 200) {
			dispatch("getUsers");
			return result;
		} else {
			return result;
		}
	},

	async deleteUser({ dispatch }, { userId }) {
		const data = await service.delete(userId);
		if (data.status == 204) {
			dispatch("getUsers");
			return data;
		} else {
			return data;
		}
	},

	async ChangeUserPassword({}, { newPassword, userId }) {
		const data = await service.changePassword(newPassword, userId);
		return data;
	},

	// Method used to export users
	async exportUsers(_, payload) {
		const { data } = await service.exportUsers(payload);
		// create a blob
		const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
		FileSaver.saveAs(blob, "users.csv");

		return data;
	},
};

export const getters = {
	getUserByRole: (state) => (role) => state.users.filter((user) => user.role == role),

	getUserById: (state) => (id) => state.users.find((user) => user._id == id),
};
