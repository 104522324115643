<template>
<div id="app">
    <router-view />
</div>
</template>

<script>
export default {
    page:{
        title: 'Home',
        titleTemplate: '%s | Technogenious Solutions'
    }
}
</script>