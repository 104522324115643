import { GameBoxService } from "../../services/gamebox.service";
var FileSaver = require("file-saver");
const service = new GameBoxService();

export const state = {
	gameboxes: [],
	gamboxSearched: {},
	totalGameboxes: 0,
	paginationConfig: {
		skip: "",
		limit: "",
		search: "",
		paginated: false,
	},
};

export const mutations = {
	SET_GAMEBOXES(state, gameboxes) {
		state.gameboxes = [...gameboxes];
	},

	SET_TOTAL_GAMEBOXES(state, total) {
		state.totalGameboxes = total;
	},

	SET_GAMEBOX_SEARCHED(state, gamebox) {
		state.gameboxSearched = { ...gamebox };
	},
};

export const actions = {
	async createGamebox({ dispatch }, payload) {
		const result = await service.create(payload || {});
		if (result.status == 201) {
			dispatch("getGameboxes");
			return result;
		} else {
			return result;
		}
	},

	async getGameboxes({ state, commit }) {
		const { paginated, skip, limit } = state.paginationConfig;
		const { data, status, message, error } = await service.get({
			paginated,
			skip,
			limit,
		});

		if (status == 200) {
			const { results, count } = data;
			commit("SET_GAMEBOXES", results ? results : []);
			commit("SET_TOTAL_GAMEBOXES", count);
			return;
		} else {
			return { status, message, error };
		}
	},

	async getGamebox({ commit }, { search }) {
		const { data, status, error, message } = await service.get({ search });

		if (status == 200) {
			const { results } = data;
			commit("SET_GAMEBOX_SEARCHED", results ? results[0] : {});
			return;
		} else {
			return { message, error, status };
		}
	},

	async updateGamebox({ dispatch }, payload) {
		const result = await service.update(payload || {});
		if (result.status == 200) {
			dispatch("getGameboxes");
			return result;
		} else {
			return result;
		}
	},
	async togglePower(_, serialNumber) {
		const result = await service.togglePower(serialNumber);
		return result;
	},

	async deleteGamebox({ dispatch }, { gameboxId }) {
		const data = await service.delete(gameboxId);

		if (data.status == 204) {
			dispatch("getGameboxes");
			return data;
		} else {
			return data;
		}
	},

	// Method used to export machines
	async exportMachines(_, payload) {
		const { data } = await service.exportMachines(payload);
		// create a blob
		const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
		FileSaver.saveAs(blob, "machines.csv");

		return data;
	},
};

export const getters = {
	getGameboxByGame: (state) => (gameId) =>
		state.gameboxes.filter((gamebox) => gamebox.games[0]._id == gameId),

	getGameboxByBranch: (state) => (branchId) =>
		state.gameboxes.filter((gamebox) => branchId == gamebox.branch._id),

	getGameboxById: (state) => (id) => state.gameboxes.find((gamebox) => id == gamebox._id),
};
