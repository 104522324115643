import { Http } from "../utils/http";

export class TransactionService extends Http {
	constructor() {
		super();
	}

	create(payload) {
		return this.httpClient().post("transactions", payload);
	}

	createReinjection(data) {
		return this.httpClient().post("transactions", data);
	}

	/**
	 * Method used to confirm that a reinjection was received by the branch admin
	 * @param {*} String transactionId
	 * @returns Transaction object
	 */
	confirm(transactionId) {
		return this.httpClient().patch(`transactions/${transactionId}/confirm`);
	}

	/**
	 * Method used to mark a transaction as complete
	 * @param {*} String transactionId
	 * @returns Transaction object
	 */
	markComplete(transactionId) {
		return this.httpClient().patch(`transactions/${transactionId}/completion`);
	}

	getFloatReinjection(payload) {
		return this.httpClient().get(`transactions/float-reinjection`, {
			params: payload,
		});
	}

	getTransactions(payload) {
		return this.httpClient().get(`transactions`, {
			params: payload,
		});
	}

	getTransactionSummaryDetails({ branchId, startDate, endDate }) {
		let queryString = `?branchId=${branchId}&startDate=${startDate}&endDate=${endDate}`;
		return this.httpClient().get(`transactions/details${queryString}`);
	}

	getTransactionSummary(groupBy, type, branchId, gameId, gameboxId, startDate, endDate) {
		let queryString = "?";
		if (groupBy) {
			queryString += `groupBy=${groupBy}&`;
		}
		if (type) {
			queryString += `type=${type}&`;
		}
		if (branchId) {
			queryString += `branchId=${branchId}&`;
		}
		if (gameId) {
			queryString += `gameId=${gameId}&`;
		}
		if (gameboxId) {
			queryString += `gameboxId=${gameboxId}&`;
		}
		if (startDate) {
			queryString += `startDate=${startDate}&`;
		}
		if (endDate) {
			queryString += `endDate=${endDate}&`;
		}
		return this.httpClient().get(`transactions/summary${queryString}`);
	}

	getMonthOrWeekTransaction(period, type, startDate, endDate, branchId) {
		let queryString = "?";
		if (period) queryString += `period=${period}&`;
		if (type) queryString += `type=${type}&`;
		if (startDate) queryString += `startDate=${startDate}&`;
		if (endDate) queryString += `endDate=${endDate}&`;
		if (branchId) queryString += `branchId=${branchId}`;

		return this.httpClient().get(`transactions/period-summary${queryString}`);
	}

	upload(fileToUpload) {
		return this.httpClient().post("transactions/upload", fileToUpload);
	}

	update(payload) {
		return this.httpClient().put(`transactions/${payload._id}`, payload);
	}

	delete(transactionId) {
		return this.httpClient().delete(`transactions/${transactionId}`);
	}

	/**
	 * Method used to get a single transaction from the API
	 * @param {*} String id
	 * @returns Transaction
	 */
	getSingleTransaction(id) {
		return this.httpClient().get(`transactions/${id}`);
	}

	/**
	 * Used to get the iot readings for a specific gamebox
	 * @param {*} id Gamebox ID
	 * @returns Array
	 */
	getLatestIotTransactions(id) {
		return this.httpClient().get(`transactions/unrecorded-iot/${id}`);
	}

	//Mark a group of transactions as completed.
	markIotComplete(payload) {
		return this.httpClient().patch(`integrations/iot/transactions`, payload);
	}

	//Queries the power state of a Iot device by it's serial Number
	getIotPowerState(id) {
		return this.httpClient().get(`integrations/iot/${id}/powerState`);
	}

	// get past transactions for float reinjection creation
	getPastTransactionDates(payload) {
		let s = "?";
		if (payload.branchId) s += `branchId=${payload.branchId}&`;
		if (payload.startDate) s += `startDate=${payload.startDate}&`;
		if (payload.endDate) s += `endDate=${payload.endDate}&`;
		return this.httpClient().get(`transactions/past-dates${s}`);
	}

	exportTransactions(payload) {
		return this.httpClient().get("transactions/export", {
			params: payload,
			responseType: "blob",
		});
	}

	exportAdditionalFloat(payload) {
		return this.httpClient().get("transactions/export-additional-floats", {
			params: payload,
			responseType: "blob",
		});
	}
}
