import { Http } from "../utils/http";

export class UsersServices extends Http {
	constructor() {
		super();
	}

	delete(userId) {
		return this.httpClient().delete(`users/${userId}`);
	}

	create(name, email, role, isActive) {
		return this.httpClient().post("users", { email, name, role, isActive });
		log(error);
	}

	changePassword(password, userId) {
		return this.httpClient().patch(`users/${userId}/change-password`, { password });
	}

	generateNewPassword(userId) {
		return this.httpClient().patch(`users/${userId}`);
	}

	get({ search, paginated, skip, limit }) {
		if (!search) search = "";
		return this.httpClient().get(`users`);
	}

	update(userId, name, email, role, isActive) {
		return this.httpClient().put(`users/${userId}`, { name, email, role, isActive });
	}

	exportUsers(payload) {
		return this.httpClient().get("users/export", { params: payload, responseType: "blob" });
	}
}
