import { BranchService } from "../../services/branch.service";
var FileSaver = require("file-saver");

const service = new BranchService();

export const state = {
	branches: [],
	totalBranches: 0,
	branchSearched: {},
	paginationConfig: {
		skip: "",
		limit: "",
		search: "",
		paginated: false,
	},
};

export const mutations = {
	SET_BRANCHES(state, branches) {
		state.branches = [...branches];
	},

	SET_TOTAL_BRANCHES(state, total) {
		state.totalBranches = total;
	},

	SET_BRANCH_SEARCHED(state, branchSearched) {
		state.branchSearched = { ...branchSearched };
	},
};

export const actions = {
	async createBranch(
		{ dispatch },
		{
			name,
			code,
			street,
			city,
			province,
			country,
			latitude,
			longitude,
			manager,
			commissionPercentage,
			locationFloat,
			isActive,
			jackpotAmount,
			jackpotPercentage,
		}
	) {
		const result = await service.create(
			name,
			code,
			street,
			city,
			province,
			country,
			latitude,
			longitude,
			manager,
			commissionPercentage,
			locationFloat,
			isActive,
			jackpotAmount,
			jackpotPercentage
		);

		if (result.status == 201) {
			dispatch("getBranches");
			return result;
		} else {
			return result;
		}
	},

	async updateBranch(
		{ dispatch },
		{
			_id,
			name,
			code,
			street,
			city,
			province,
			country,
			latitude,
			longitude,
			manager,
			commissionPercentage,
			locationFloat,
			isActive,
			jackpotAmount,
			jackpotPercentage,
		}
	) {
		const result = await service.update(
			_id,
			name,
			code,
			street,
			city,
			province,
			country,
			latitude,
			longitude,
			manager,
			commissionPercentage,
			locationFloat,
			isActive,
			jackpotAmount,
			jackpotPercentage
		);

		if (result.status == 200) {
			dispatch("getBranches");
			return result;
		} else {
			return result;
		}
	},

	async getBranches({ state, commit }) {
		const { skip, limit, paginated } = state.paginationConfig;
		const result = await service.get({ skip, limit, paginated });
		if (result.status === 200) {
			const { results, count } = result.data;
			let branches = [];
			if (Array.isArray(results))
				branches = results.filter(
					(branch) => branch.isDeleted == false || !branch.hasOwnProperty("isDeleted")
				);
			else branches = [];
			commit("SET_BRANCHES", branches);
			commit("SET_TOTAL_BRANCHES", count);
			return result;
		} else {
			return result;
		}
	},

	async getBranch({ commit }, { search }) {
		const { status, message, error, data } = await service.get({ search });

		if (status === 200) {
			const { results } = data;
			commit("SET_BRANCH_SEARCHED", results ? results[0] : {});
			return;
		} else {
			return { message, error, status };
		}
	},

	async deleteBranch({ dispatch }, { branchId }) {
		const result = await service.delete(branchId);
		if (result.status == 204) {
			dispatch("getBranches");
			return result;
		} else {
			return result;
		}
	},

	// Method used to export branch information
	async exportBranch(_, payload) {
		const results = await service.exportBranch(payload);
		// create a blob
		const blob = new Blob([results.data], { type: "text/csv;charset=utf-8" });
		FileSaver.saveAs(blob, "branches.csv");

		return results.data;
	},
};

export const getters = {
	getBranchById: (state) => (id) => state.branches.find((branch) => branch._id == id),
};
