import store from "@/store/store";

export default [
	{
		path: "/login",
		name: "login",
		component: () => import("../views/pages/account/login"),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["authModule/loggedIn"]) {
					// Redirect to the home page instead
					if (store.getters["authModule/user"]?.role == "ADMIN") {
						return next({
							name: "admin-home",
							query: { redirectFrom: routeTo.fullPath },
						});
					} else if (
						store.getters["authModule/user"]?.role == "BRANCH-MANAGER"
					) {
						return next({
							name: "manager-home",
							query: { redirectFrom: routeTo.fullPath },
						});
					} else if (store.getters["authModule/user"]?.role == "EMPLOYEE") {
						return next({
							name: "employee-home",
							query: { redirectFrom: routeTo.fullPath },
						});
					}
				} else {
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: "/register",
		name: "register",
		component: () => import("../views/pages/account/register"),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["authModule/loggedIn"]) {
					// Redirect to the home page instead
					if (store.getters["authModule/user"]?.role == "ADMIN") {
						return next({
							name: "admin-home",
							query: { redirectFrom: routeTo.fullPath },
						});
					} else if (
						store.getters["authModule/user"]?.role == "BRANCH-MANAGER"
					) {
						return next({
							name: "manager-home",
							query: { redirectFrom: routeTo.fullPath },
						});
					} else if (store.getters["authModule/user"]?.role == "EMPLOYEE") {
						return next({
							name: "employee-home",
							query: { redirectFrom: routeTo.fullPath },
						});
					}
				} else {
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: "/forgot-password",
		name: "Forgot-password",
		component: () => import("../views/pages/account/forgot-password"),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["authModule/loggedIn"]) {
					// Redirect to the home page instead
					if (store.getters["authModule/user"]?.role == "ADMIN") {
						return next({
							name: "admin-home",
							query: { redirectFrom: routeTo.fullPath },
						});
					} else if (
						store.getters["authModule/user"]?.role == "BRANCH-MANAGER"
					) {
						return next({
							name: "manager-home",
							query: { redirectFrom: routeTo.fullPath },
						});
					} else if (store.getters["authModule/user"]?.role == "EMPLOYEE") {
						return next({
							name: "employee-home",
							query: { redirectFrom: routeTo.fullPath },
						});
					}
				} else {
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: "/confirm-request",
		name: "Forgot-password-confirm",
		component: () =>
			import("../views/pages/account/ConfirmPasswordChangeToken"),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["authModule/loggedIn"]) {
					// Redirect to the home page instead
					if (store.getters["authModule/user"]?.role == "ADMIN") {
						return next({
							name: "admin-home",
							query: { redirectFrom: routeTo.fullPath },
						});
					} else if (
						store.getters["authModule/user"]?.role == "BRANCH-MANAGER"
					) {
						return next({
							name: "manager-home",
							query: { redirectFrom: routeTo.fullPath },
						});
					} else if (store.getters["authModule/user"]?.role == "EMPLOYEE") {
						return next({
							name: "employee-home",
							query: { redirectFrom: routeTo.fullPath },
						});
					}
				} else {
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: "/logout",
		name: "logout",
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
					store.dispatch("auth/logOut");
				} else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
					store.dispatch("authfack/logout");
				}
				const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
					route.push("/login")
				);
				// Navigate back to previous page, or home as a fallback
				next(authRequiredOnPreviousRoute ? { name: "home" } : { ...routeFrom });
			},
		},
	},
	{
		path: "/",
		name: "home",
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["authModule/loggedIn"]) {
					// Redirect to the home page instead
					if (store.getters["authModule/user"].role == "ADMIN") {
						return next({
							name: "admin-home",
							query: { redirectFrom: routeTo.fullPath },
						});
					} else if (
						store.getters["authModule/user"].role == "BRANCH-MANAGER"
					) {
						return next({
							name: "manager-home",
							query: { redirectFrom: routeTo.fullPath },
						});
					} else if (store.getters["authModule/user"].role == "EMPLOYEE") {
						return next({
							name: "employee-home",
							query: { redirectFrom: routeTo.fullPath },
						});
					}
				} else {
					// Continue to the login page
					next({ name: "login" });
				}
			},
		},
		component: () => import("../views/pages/dashboard/superAdmin/index"),
	},
	{
		path: "/dashboard/super-admin",
		name: "admin-home",
		meta: {
			authRequired: true,
			roleRequired: true,
			privillage: "ADMIN",
		},
		component: () => import("../views/pages/dashboard/superAdmin/index"),
	},
	{
		path: "/dashboard/super-admin/reports/branchReport",
		name: "branch report",
		meta: {
			authRequired: true,
			roleRequired: true,
			privillage: "ADMIN",
		},
		component: () => import("../views/pages/dashboard/superAdmin/branchReport"),
	},
	{
		path: "/dashboard/super-admin/reports/overallReport",
		name: "overall report",
		meta: {
			authRequired: true,
			roleRequired: true,
			privillage: "ADMIN",
		},
		component: () =>
			import("../views/pages/dashboard/superAdmin/overallReport"),
	},
	{
		path: "/dashboard/super-admin/reports/compareBranches",
		name: "compare branches",
		meta: {
			authRequired: true,
			roleRequired: true,
			privillage: "ADMIN",
		},
		component: () =>
			import("../views/pages/dashboard/superAdmin/compareBranches"),
	},
	{
		path: "/dashboard/super-admin/commission",
		name: "commissions",
		meta: {
			authRequired: true,
			roleRequired: true,
			privillage: "ADMIN",
		},
		component: () => import("../views/pages/dashboard/superAdmin/commission"),
	},
	{
		path: "/dashboard/super-admin/transactions",
		name: "transactions",
		meta: {
			authRequired: true,
			roleRequired: true,
			privillage: "ADMIN",
		},
		component: () =>
			import("../views/pages/dashboard/superAdmin/transactionReport"),
	},
	{
		path: "/dashboard/super-admin/floats",
		name: "floats",
		meta: {
			authRequired: true,
			roleRequired: true,
			privillage: "ADMIN",
		},
		component: () => import("../views/pages/dashboard/superAdmin/floats"),
	},
	{
		path: "/dashboard/super-admin/uploadTransaction",
		name: "admin-employee-home",
		meta: {
			authRequired: true,
			roleRequired: true,
			privillage: "ADMIN",
		},
		component: () =>
			import("../views/pages/dashboard/superAdmin/uploadTransaction"),
	},
	{
		path: "/dashboard/super-admin/settings",
		name: "settings",
		meta: {
			authRequired: true,
			roleRequired: true,
			privillage: "ADMIN",
		},
		component: () => import("../views/pages/dashboard/superAdmin/settings"),
	},

	{
		path: "/dashboard/manager",
		name: "manager-home",
		meta: {
			authRequired: true,
			roleRequired: true,
			privillage: "BRANCH-MANAGER",
		},
		component: () => import("../views/pages/dashboard/branchAdmin/index"),
	},
	{
		path: "/employee",
		name: "employee-home",
		meta: {
			authRequired: true,
			roleRequired: true,
			privillage: "EMPLOYEE",
		},
		component: () => import("../views/pages/dashboard/employees/index"),
	},
	{
		path: "/complete-transaction/:id",
		name: "bearer-complete",
		component: () => import("../views/pages/outside/complete-transaction"),
	},
];
