export const menuItems = [
 	{
		id: 1,
		label: "menuitems.dashboard.text",
		icon: "ri-dashboard-line",
		link: "/dashboard/super-admin",
	},
	{
		id: 2,
		label: "menuitems.reports.text",
		icon: "ri-file-chart-line",
		isMenuCollapsed: false,
		subItems: [
			{
				id: 3,
				label: "menuitems.reports.list.overallReport",
				link: "/dashboard/super-admin/reports/overallReport",
			},
			{
				id: 4,
				label: "menuitems.reports.list.branchReport",
				link: "/dashboard/super-admin/reports/branchReport",
			},
			{
				id: 5,
				label: "menuitems.reports.list.compareBranches",
				link: "/dashboard/super-admin/reports/compareBranches",
			},
		],
	},
	{
		id: 6,
		label: "menuitems.commission.text",
		icon: "ri-money-dollar-box-line",
		link: "/dashboard/super-admin/commission",
	},
	{
		id: 7,
		label: "menuitems.transactions.text",
		icon: "ri-money-dollar-box-line",
		link: "/dashboard/super-admin/transactions",
	},
	{
		id: 8,
		label: "menuitems.floats.text",
		icon: "ri-money-dollar-box-line",
		link: "/dashboard/super-admin/floats",
	},
	{
		id: 9,
		label: "menuitems.settings.text",
		icon: "ri-settings-2-line",
		link: "/dashboard/super-admin/settings",
	},

   
];

