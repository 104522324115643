import { Http } from "../utils/http";

export class BranchService extends Http {
	constructor() {
		super();
	}

	create(
		name,
		code,
		street,
		city,
		province,
		country,
		latitude,
		longitude,
		manager,
		commissionPercentage,
		locationFloat,
		isActive,
		jackpotAmount,
		jackpotPercentage
	) {
		return this.httpClient().post("branches", {
			name,
			code,
			street,
			city,
			province,
			country,
			latitude,
			longitude,
			manager,
			commissionPercentage,
			locationFloat,
			isActive,
			jackpotAmount,
			jackpotPercentage,
		});
	}

	get({ search, paginated, skip, limit }) {
		if (!search) search = "";
		return this.httpClient().get(`branches?search=${search}`, {
			paginated,
			skip,
			limit,
		});
	}

	update(
		branchId,
		name,
		code,
		street,
		city,
		province,
		country,
		latitude,
		longitude,
		manager,
		commissionPercentage,
		locationFloat,
		isActive,
		jackpotAmount,
		jackpotPercentage
	) {
		return this.httpClient().patch(`branches/${branchId}`, {
			name,
			code,
			street,
			city,
			province,
			country,
			latitude,
			longitude,
			manager,
			commissionPercentage,
			locationFloat,
			isActive,
			jackpotAmount,
			jackpotPercentage,
		});
	}

	delete(branchId) {
		return this.httpClient().delete(`branches/${branchId}`);
	}

	exportBranch(payload) {
		return this.httpClient().get("branches/export", { params: payload, responseType: "blob" });
	}
}
